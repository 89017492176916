@import '../../styles/fonts.scss';
@import '../../styles/theme.scss';

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 31%;
  margin-top: $header-height + $header-margin-top;
  position: absolute;
  user-select: none;
  pointer-events: none;

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-logo-container {
      height: 10%;
      margin-top: 3.2em;
    }
  }
}

.logo-header {
  display: flex;
  font-size: 11vw;
  letter-spacing: -5px;
  align-items: center;
  justify-content: center;
  color: $color-logo;
}

.logo-footer {
  display: flex;
  font-size: 2vw;
  letter-spacing: -0.5px;
  align-items: center;
  justify-content: center;
  color: $color-logo-subtitle;

  @media only screen and (max-width: $responsive-hide-step1) {
    &.rsp-logo-footer {
      letter-spacing: 0;
    }
  }

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-logo-footer {
      letter-spacing: 0;
    }
  }
}